import React from 'react'
import { connect } from 'react-redux'
import {
	backToList,
	savePollChanges,
	deletePoll,
	updateCurPollField,
	openConstructor,
	openStartPollModal,
	resetResultsOfPoll,
} from '../actions/actions'
import { Panel, PanelHeader, Group, HeaderButton } from '@vkontakte/vkui'

import { Button, Textarea, DropDown, Input, Notify } from '@happysanta/vk-app-ui'

import Toggler from '../components/Toggler'

import './Settings.sass'

import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back'
import Icon24BrowserForward from '@vkontakte/icons/dist/24/browser_forward'

const mapStateToProps = state => ({
	...state,
})

const timeOptions = {
	'30 минут': 1800,
	'1 час': 3600,
}

const mapDispatchToProps = dispatch => ({
	backToList: () => dispatch(backToList()),
	savePollChanges: (poll_id, new_poll) => dispatch(savePollChanges(poll_id, new_poll)),
	deletePoll: poll_id => dispatch(deletePoll(poll_id)),
	updateCurPollField: ({ target }) => {
		const { name, value } = target

		dispatch(updateCurPollField(name, value))
	},
	updateCurPollSwitch: (name, value) => {
		dispatch(updateCurPollField(name, value))
	},
	openConstructor: poll_id => dispatch(openConstructor(poll_id)),
	openStartPollModal: () => dispatch(openStartPollModal()),
	resetResultsOfPoll: poll_id => dispatch(resetResultsOfPoll(poll_id)),
})

class Settings extends React.Component {
	render() {
		return (
			<Panel id={this.props.id}>
				<PanelHeader
					left={
						<HeaderButton onClick={this.props.backToList}>
							<Icon24BrowserBack /> Назад
						</HeaderButton>
					}
				>
					{this.props.cur_poll !== null ? this.props.cur_poll.name : null}
				</PanelHeader>
				{this.props.cur_poll !== null ? (
					<Group>
						<div
							className="Settings__constructor"
							onClick={() => this.props.openConstructor(this.props.cur_poll._id)}
						>
							<div className="item">Конструктор опроса</div>
							<Icon24BrowserForward fill="#99A2AD" />
						</div>
						<div className="Settings__block">
							<div className="Settings__item not_line">
								Название
								<Input
									name="name"
									onChange={this.props.updateCurPollField}
									value={this.props.cur_poll.name}
									className="item Settings__input"
									placeholder="Введите название опроса"
								/>
							</div>
							<div className="Settings__item">
								Активность
								<Toggler
									mobile={true}
									className="switch"
									checked={this.props.cur_poll.active}
									onChange={() => {
										this.props.updateCurPollSwitch('active', !this.props.cur_poll.active)
									}}
								/>
							</div>
							<div className="Settings__item not_line">
								Ключ сообщества
								<Input
									name="token"
									onChange={this.props.updateCurPollField}
									value={this.props.cur_poll.token}
									className="item Settings__input"
									placeholder="Вставьте ключ сообщества"
								/>
							</div>

							<div className="Settings__item not_line">
								Временное ограничение опроса
								<DropDown
									className="item"
									onSelect={val => {
										this.props.updateCurPollSwitch('duration', timeOptions[val])
									}}
									selected={
										Object.entries(timeOptions).find(e => e[1] === this.props.cur_poll.duration)[0]
									}
									placeholder="Выберите один параметер"
									single={true}
									items={Object.keys(timeOptions)}
								/>
							</div>

							<div className="Settings__item not_line">
								Автоответ после ограничения
								<Textarea
									name="autoanswer"
									value={this.props.cur_poll.autoanswer}
									onChange={this.props.updateCurPollField}
									className="item Settings__input"
									placeholder="Введите сообщение..."
									rows={5}
								/>
							</div>

							<div
								className="Settings__line"
								style={{
									width: '100%',
									height: '1px',
									backgroundColor: '#ccd4da',
								}}
							/>
							<div className="Setting__blue_buttom__block">
								<div
									className="item"
									onClick={() => {
										window.open(`/generatexlsx?poll_id=${this.props.cur_poll._id}`)
									}}
								>
									Выгрузить статистику
								</div>
								<div className="right">·</div>
								<div className="item" onClick={this.props.openStartPollModal}>
									Загрузить список пользователей
								</div>
								<div className="right">·</div>
								<div
									className="item"
									onClick={() => this.props.resetResultsOfPoll(this.props.cur_poll._id)}
								>
									Сбросить результаты
								</div>
							</div>
							<div className="Settings__buttom__block">
								<Button
									className="Settings__buttom"
									onClick={() => {
										if (this.props.cur_poll.name.length === 0) alert('Укажите имя опроса')
										else this.props.savePollChanges(this.props.cur_poll._id, this.props.cur_poll)
									}}
								>
									Сохранить
								</Button>
								<Button
									className="Settings__buttom"
									type="secondary"
									onClick={() => this.props.deletePoll(this.props.cur_poll._id)}
								>
									Удалить опрос
								</Button>
							</div>
							{this.props.cur_poll_save_status !== null && (
								<Notify type={this.props.cur_poll_save_status === true ? 'success' : 'error'}>
									{this.props.cur_poll_save_status === true
										? 'Изменения сохранены.'
										: 'Не удалось сохранить изменения. Проверьте токен сообщества.'}
								</Notify>
							)}
						</div>
					</Group>
				) : null}
			</Panel>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Settings)
