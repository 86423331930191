export const FETCH_POLLS = 'FETCH_POLLS'
export const SWITCH_FILTER = 'SWITCH_FILTER'
export const ADD_POLL = 'ADD_POLL'
export const OPEN_SETTINGS_FOR_NEW_QUESTION = 'OPEN_SETTINGS_FOR_NEW_QUESTION'
export const CLOSE_SETTINGS_FOR_NEW_QUESTION = 'CLOSE_SETTINGS_FOR_NEW_QUESTION'
export const UPDATE_FIELD_IN_OPTION_INPUT = 'UPDATE_FIELD_IN_OPTION_INPUT'
export const DELETE_INPUT_IN_QUESTION = 'DELETE_INPUT_IN_QUESTION'
export const SWITCH_TYPE_QUESTION = 'SWITCH_TYPE_QUESTION'
export const UPDATE_NAME_NEW_QUESTION = 'UPDATE_NAME_NEW_QUESTION'
export const ADD_QUESTION = 'ADD_QUESTION'
export const DELETE_QUESTION = 'DELETE_QUESTION'
export const OPEN_QUESTION_EDITOR = 'OPEN_QUESTION_EDITOR'

export const EDIT_QUESTION = 'EDIT_QUESTION'
export const OPEN_EDIT_QUESTION_MODAL = 'OPEN_EDIT_QUESTION_MODAL'
export const CLOSE_EDIT_QUESTION_MODAL = 'CLOSE_EDIT_QUESTION_MODAL'

export const OPEN_SETTINGS_FOR_NEW_POLL = 'OPEN_SETTINGS_FOR_NEW_POLL'
export const CLOSE_SETTINGS_FOR_NEW_POLL = 'CLOSE_SETTINGS_FOR_NEW_POLL'
export const UPDATE_FIELD = 'UPDATE_FIELD'
export const UPDATE_CUR_POLL_FIELD = 'UPDATE_CUR_POLL_FIELD'
export const OPEN_POLL_SETTINGS = 'OPEN_POLL_SETTINGS'
export const SAVE_POLL_CHANGES = 'SAVE_POLL_CHANGES'
export const DELETE_POLL = 'DELETE_POLL'
export const BACK_TO_LIST = 'BACK_TO_LIST'
export const BACK_TO_SETTINGS = 'BACK_TO_SETTINGS'
export const SAVE_POLL_CHANGES_FAIL = 'SAVE_POLL_CHANGES_FAIL'
export const OPEN_CONSTRUCTOR = 'OPEN_CONSTRUCTOR'
export const OPEN_QUESTION_ACTION_MENU = 'OPEN_QUESTION_ACTION_MENU'
export const CHOOSE_ANSWER_ACTION = 'CHOOSE_ANSWER_ACTION'
export const ADD_QUESTION_INPUT = 'ADD_QUESTION_INPUT'
export const EDIT_QUESTION_OPTIONS_INPUT = 'EDIT_QUESTION_OPTIONS_INPUT'
export const SET_OPTION_NEXT_QUESTION = 'SET_OPTION_NEXT_QUESTION'

export const MARK_OPTION_AS_DELETED = 'MARK_OPTION_AS_DELETED'
export const OPEN_START_POLL_MODAL = 'OPEN_START_POLL_MODAL'
export const CLOSE_START_POLL_MODAL = 'CLOSE_START_POLL_MODAL'

export const START_POLL = 'START_POLL'

export const HIDE_QUESTION = 'HIDE_QUESTION'
export const FLAUNT_QUESTION = 'FLAUNT_QUESTION'

export const RESET_RESULT_OF_POLL = 'RESET_RESULT_OF_POLL'

export const REPLACE_QUESTION_POSITION = 'REPLACE_QUESTION_POSITION'

export const SWITCH_QUESTION_BUTTONS = 'SWITCH_QUESTION_BUTTONS'

export const ADD_ATTACH_OPEN = 'ADD_ATTACH_OPEN'
export const ADD_ATTACH_CLOSE = 'ADD_ATTACH_CLOSE'
export const ADDED_ATTACH = 'ADDED_ATTACH'
export const DELETED_ATTACH = 'DELETED_ATTACH'
