import React from 'react'
import { connect } from 'react-redux'
import {
	changePollsFilter,
	addPoll,
	openSettingsOfNewPoll,
	openPollSettings,
} from '../actions/actions'
import { Panel, PanelHeader, Cell, List } from '@vkontakte/vkui'

import './PollsList.sass'

import Icon16Chevron from '@vkontakte/icons/dist/16/chevron'

import ActiveStatus from '../components/ActiveStatus'
import AddPoll from '../components/AddPoll'

import { Button } from '@happysanta/vk-app-ui'

const mapStateToProps = state => ({
	...state,
})

const mapDispatchToProps = dispatch => ({
	addPoll: name => dispatch(addPoll(name)),
	changePollsFilter: filter => dispatch(changePollsFilter(filter)),
	openSettingsOfNewPoll: () => dispatch(openSettingsOfNewPoll()),
	openPollSettings: poll_id => dispatch(openPollSettings(poll_id)),
})

class PollsList extends React.Component {
	render() {
		const needList =
			this.props.polls_filter === 'no_active' ? this.props.no_active_polls : this.props.active_polls
		return (
			<Panel id={this.props.id}>
				<PanelHeader
					left={<ActiveStatus {...this.props} onChangeFilter={this.props.changePollsFilter} />}
					right={<AddPoll onClick={this.props.openSettingsOfNewPoll} />}
				/>

				<List ref={this.nav}>
					{needList.length > 0 ? (
						needList.map(poll => (
							<Cell
								className="Pointer"
								key={poll.id}
								description={poll.group_name}
								asideContent={<Icon16Chevron />}
								onClick={() => {
									this.props.openPollSettings(poll._id)
								}}
							>
								{poll.name}
							</Cell>
						))
					) : (
						<div className="NoPollsInList">
							<div className="item">
								<div className="text">Опросов еще нет</div>
								<div>
									<Button onClick={this.props.openSettingsOfNewPoll}>Создать опрос</Button>
								</div>
							</div>
						</div>
					)}
				</List>
			</Panel>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PollsList)
