import React from 'react'
import { connect } from 'react-redux'

import { openStartPollModal, closeStartPollModal, updateField, startPoll } from '../actions/actions'

import Modal from '../components/Modal'
import classNames from 'classnames'
import './StartPollModal.sass'

import { Textarea, Button, Checkbox } from '@happysanta/vk-app-ui'

import Icon16Cancel from '@vkontakte/icons/dist/16/cancel'

const mapStateToProps = state => ({
	...state,
})

const filterInt = value => {
	if (/^(\-|\+)?([0-9]+|Infinity)$/.test(value)) return Number(value)
	return NaN
}

const validateUserList = userIds => {
	const users = userIds.split('\n').map(e => filterInt(e))
	return users.some(e => isNaN(e)) === false
}

const mapDispatchToProps = dispatch => ({
	openStartPollModal: () => dispatch(openStartPollModal()),
	closeStartPollModal: () => dispatch(closeStartPollModal()),
	updateField: ({ target }) => {
		const { name, value } = target

		dispatch(updateField(name, value))
	},
	updateCheck: value => dispatch(updateField('agreeStart', value)),
	startPoll: (poll_id, user_ids) => dispatch(startPoll(poll_id, user_ids)),
})

class CreatePollModal extends React.Component {
	render() {
		return (
			<Modal>
				<div className="BlueTitle">
					<div className="BlueTitle__block">
						<div className="BlueTitle__title">Загрузить список</div>
						<Icon16Cancel fill="white" onClick={this.props.closeStartPollModal} />
					</div>
				</div>
				<div className="Modal__content">
					<div className="item last">
						<div className="title">ID пользователей</div>
						<Textarea
							name="start_poll_user_list"
							value={this.props.start_poll_user_list}
							placeholder="Вставьте id"
							onChange={this.props.updateField}
							rows={4}
						/>
						<div style={{ marginTop: 18 }} />
						<Checkbox
							checked={this.props.agreeStart}
							onChange={() => this.props.updateCheck(!this.props.agreeStart)}
						>
							Я действительно хочу запустить бота и разослать эти сообщения
						</Checkbox>
					</div>
				</div>
				<div className="GreyFooter">
					<div className="item">
						<Button type="transparent" onClick={this.props.closeStartPollModal}>
							Отмена
						</Button>
					</div>
					<div
						className="item"
						onClick={() => {
							if (
								this.props.agreeStart === false ||
								this.props.start_poll_user_list === undefined ||
								this.props.start_poll_user_list.length === 0 ||
								validateUserList(this.props.start_poll_user_list) === false
							)
								return
							this.props.startPoll(this.props.cur_poll._id, this.props.start_poll_user_list)
						}}
					>
						<Button
							type="default"
							className={classNames('btn', {
								disable:
									this.props.agreeStart === false ||
									this.props.start_poll_user_list === undefined ||
									this.props.start_poll_user_list.length === 0 ||
									validateUserList(this.props.start_poll_user_list) === false,
							})}
						>
							Разослать
						</Button>
					</div>
				</div>
			</Modal>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreatePollModal)
