import React from 'react'
import { connect } from 'react-redux'
import { closeAttachModal, addAttach, updateField } from '../actions/actions'

import Icon16Cancel from '@vkontakte/icons/dist/16/cancel'

import './CreateQuestionModal.sass'

import Modal from '../components/Modal'

import { Input, Button } from '@happysanta/vk-app-ui'

const mapStateToProps = state => ({
	...state,
})

const mapDispatchToProps = dispatch => ({
	updateField: ({ target }) => {
		const { name, value } = target

		dispatch(updateField(name, value))
	},
	closeAttachModal: () => dispatch(closeAttachModal()),
	addAttach: (question_id, url) => dispatch(addAttach(question_id, url)),
})

class AddAttachmentsModal extends React.Component {
	render() {
		return (
			<Modal>
				<div className="BlueTitle">
					<div className="BlueTitle__block">
						<div className="BlueTitle__title">{`Добавление ${
							this.props.add_attach_type === 'photo' ? 'фотографии' : 'видеозаписи'
						}`}</div>
						<Icon16Cancel
							fill="white"
							onClick={this.props.closeAttachModal}
							className="close_modal"
						/>
					</div>
				</div>
				<div className="Modal__content">
					<div className="item last">
						<div className="title">{`Ссылка на ${
							this.props.add_attach_type === 'photo' ? 'фотографию' : 'видеозапись'
						} ВКонтакте`}</div>
						<Input
							name="add_attach_url"
							value={this.props.add_attach_url}
							placeholder="Вставьте ссылку"
							onChange={this.props.updateField}
						/>
					</div>
				</div>
				<div className="GreyFooter">
					<div className="item">
						<Button type="transparent" onClick={this.props.closeAttachModal}>
							Отмена
						</Button>
					</div>
					<div className="item">
						<Button
							type="default"
							onClick={() => {
								this.props.addAttach(this.props.cur_question._id, this.props.add_attach_url)
							}}
						>
							Добавить
						</Button>
					</div>
				</div>
			</Modal>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddAttachmentsModal)
