import React from 'react'

import './Photo.sass'

import Icon16Cancel from '@vkontakte/icons/dist/16/cancel'

const Photo = props => (
	<div className="Photo">
		<div
			style={{
				backgroundImage: `url(${props.url})`,
			}}
			className="item"
			key={props.url}
		>
			{props.onRemove && (
				<div className="remove" onClick={props.onRemove}>
					<Icon16Cancel />
				</div>
			)}
		</div>
	</div>
)

export default React.memo(Photo)
