import React from 'react'
import { connect } from 'react-redux'
import { fetchData } from './actions/actions'

import { View, ConfigProvider, PopoutWrapper } from '@vkontakte/vkui'
import '@vkontakte/vkui/dist/vkui.css'
import './vkui_fix.sass'
import '@happysanta/vk-app-ui/dist/vkappui.css'

import PollsList from './panels/PollsList'
import Settings from './panels/Settings'
import CreateQuestionModal from './panels/CreateQuestionModal'
import CreatePollModal from './panels/CreatePollModal'
import StartPollModal from './panels/StartPollModal'
import EditQuestionModal from './panels/EditQuestionModal'
import Constructor from './panels/Constructor'
import AddAttachmentsModal from './panels/AddAttachmentModal'
window._hsMobileUI = false

const mapStateToProps = state => ({
	...state,
})

const mapDispatchToProps = dispatch => ({
	fetchData: () => dispatch(fetchData()),
})

class App extends React.Component {
	componentWillMount() {
		this.props.fetchData()
	}

	render() {
		return (
			<ConfigProvider isWebView={true} scheme="bright_light">
				<View
					activePanel={this.props.activePanel}
					popout={
						(this.props.add_attach_question_id && (
							<PopoutWrapper v="center" h="center">
								<AddAttachmentsModal />
							</PopoutWrapper>
						)) ||
						(this.props.isCreateQuestion && (
							<PopoutWrapper v="center" h="center">
								<CreateQuestionModal />
							</PopoutWrapper>
						)) ||
						(this.props.isCreatePoll && (
							<PopoutWrapper v="center" h="center">
								<CreatePollModal />
							</PopoutWrapper>
						)) ||
						(this.props.isStartingPoll && (
							<PopoutWrapper v="center" h="center">
								<StartPollModal />
							</PopoutWrapper>
						)) ||
						(this.props.isEditingQuestion && (
							<PopoutWrapper v="center" h="center">
								<EditQuestionModal />
							</PopoutWrapper>
						))
					}
				>
					<PollsList id="PollsList" />
					<Settings id="Settings" />
					<Constructor id="Constructor" />
				</View>
			</ConfigProvider>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(App)
