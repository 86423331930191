import React from 'react'
import classNames from 'classnames'

import './AddPoll.sass'

import Icon16Add from '@vkontakte/icons/dist/16/add'

const AddQuestion = props => (
	<div className={classNames('AddPoll__block')} {...props}>
		<Icon16Add />
		<div className="AddPoll__item">Добавить вопрос</div>
	</div>
)

export default React.memo(AddQuestion)
