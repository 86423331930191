import React from 'react'
import { connect } from 'react-redux'
import {
	addQuestionInput,
	updateFieldInOptionInput,
	deleteInputInQuestion,
	markOptionAsDeleted,
	switchTypeQuestion,
	updateNameNewQuestion,
	editQuestion,
	closeQuestionEditorModal,
	switchQuestionWithButton,
	openAttachModal,
	deleteAttach,
} from '../actions/actions'
import classNames from 'classnames'

import Icon16Cancel from '@vkontakte/icons/dist/16/cancel'
import Icon24Camera from '@vkontakte/icons/dist/24/camera'
import Icon24Video from '@vkontakte/icons/dist/24/video'
import Toggler from '../components/Toggler'
import './CreateQuestionModal.sass'
import './EditQuestion.sass'
import Modal from '../components/Modal'
import Photo from '../components/Photo'
import { Input, Button, Textarea } from '@happysanta/vk-app-ui'

const mapStateToProps = state => ({
	...state,
})

const mapDispatchToProps = dispatch => ({
	addQuestionInput: () => dispatch(addQuestionInput()),
	updateFieldInOptionInput: (index, value) => dispatch(updateFieldInOptionInput(index, value)),
	deleteInputInQuestion: index => dispatch(deleteInputInQuestion(index)),
	switchTypeQuestion: () => dispatch(switchTypeQuestion()),
	updateNameNewQuestion: new_name => dispatch(updateNameNewQuestion(new_name)),
	editQuestion: edited_question => dispatch(editQuestion(edited_question)),
	closeQuestionEditorModal: () => dispatch(closeQuestionEditorModal()),
	markOptionAsDeleted: option_id => dispatch(markOptionAsDeleted(option_id)),
	switchQuestionWithButton: question_id => dispatch(switchQuestionWithButton(question_id)),
	openAttachModal: (question_id, type) => dispatch(openAttachModal(question_id, type)),
	deleteAttach: attachment_id => dispatch(deleteAttach(attachment_id)),
})

class EditQuestionModal extends React.Component {
	render() {
		return (
			<Modal>
				<div className="BlueTitle">
					<div className="BlueTitle__block">
						<div className="BlueTitle__title">Редактирование вопроса</div>
						<Icon16Cancel fill="white" onClick={this.props.closeQuestionEditorModal} />
					</div>
				</div>
				<div className="Modal__content">
					<div className="item">
						<div className="title">Вопрос</div>
						<Textarea
							placeholder="Введите вопрос..."
							value={this.props.cur_question.name}
							onChange={e => this.props.updateNameNewQuestion(e.target.value)}
							rows={2}
						/>
					</div>
					<div className="item type">
						<div className="title">Тип ответа</div>
						<div>
							<div
								className={classNames('radiobtn', {
									on: this.props.cur_question.type === true,
								})}
								onClick={() => {
									if (this.props.cur_question.type !== true) this.props.switchTypeQuestion()
								}}
							>
								С вариантом ответа
							</div>
							<div
								className={classNames('radiobtn', {
									on: this.props.cur_question.type === false,
								})}
								onClick={() => {
									if (this.props.cur_question.type !== false) this.props.switchTypeQuestion()
								}}
							>
								Со свободным ответом
							</div>
						</div>
					</div>
					{this.props.cur_question.type !== false && (
						<div className="item">
							<div className="title">Варианты ответа</div>
							{this.props.cur_question.options
								.filter(e => e.deleted === undefined)
								.map((option, i) => (
									<div
										key={option.index || option._id}
										style={{
											position: 'relative',
											width: '100%',
										}}
									>
										{i ===
										this.props.cur_question.options.filter(e => e.deleted === undefined).length -
											1 ? (
											<Input
												className="input add before_button"
												placeholder="Добавить вариант"
												onClick={this.props.addQuestionInput}
											/>
										) : (
											<Input
												className="input"
												value={option.name || option.value}
												onChange={e =>
													this.props.updateFieldInOptionInput(
														option.index || option._id,
														e.target.value
													)
												}
												placeholder={`Вариант ${i + 1}`}
											/>
										)}
										{i !==
											this.props.cur_question.options.filter(e => e.deleted === undefined).length -
												1 && (
											<div
												className="icon"
												style={{ position: 'absolute' }}
												onClick={() => {
													if (option.index == undefined) this.props.markOptionAsDeleted(option._id)
													else this.props.deleteInputInQuestion(option || option._id)
												}}
											>
												<Icon16Cancel fill="#BFC8D3" />
											</div>
										)}
									</div>
								))}
						</div>
					)}
					{this.props.cur_question.photos.length > 0 && (
						<div className="Photos">
							{this.props.cur_question.photos.map(photo => (
								<Photo
									url={photo.sizes[photo.sizes.length - 1].url}
									onRemove={() => {
										this.props.deleteAttach(photo._id)
									}}
								/>
							))}
						</div>
					)}
					{this.props.cur_question.type !== false && (
						<div className="Settings__item Toggler_active">
							Кнопки с вариантами
							<Toggler
								mobile={true}
								className="switch"
								checked={this.props.cur_question.withButtons}
								onChange={() => this.props.switchQuestionWithButton(this.props.cur_question._id)}
							/>
						</div>
					)}
					<div className="line" />
					<div className="attach__block">
						<div
							className="item"
							onClick={() => {
								this.props.openAttachModal(this.props.cur_question._id, 'photo')
							}}
						>
							<div className="icon">
								<Icon24Camera />
							</div>
							Фотография
						</div>
						<div className="item">
							<div className="icon">
								<Icon24Video />
							</div>
							<div className="text">Видеозаписи</div>
						</div>
					</div>
				</div>
				<div className="GreyFooter">
					<div className="item">
						<Button type="transparent" onClick={this.props.closeQuestionEditorModal}>
							Отмена
						</Button>
					</div>
					<div className="item">
						<Button type="default" onClick={() => this.props.editQuestion(this.props.cur_question)}>
							Сохранить вопрос
						</Button>
					</div>
				</div>
			</Modal>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditQuestionModal)
