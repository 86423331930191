import React from 'react'
import { connect } from 'react-redux'
import {
	changePollsFilter,
	addPoll,
	fetchData,
	openSettingsOfNewQuestion,
	closeSettingsOfNewQuestion,
	addQuestionInput,
	updateFieldInOptionInput,
	deleteInputInQuestion,
	switchTypeQuestion,
	updateNameNewQuestion,
	addQuestion,
	switchQuestionWithButton,
} from '../actions/actions'
import classNames from 'classnames'

import Icon16Cancel from '@vkontakte/icons/dist/16/cancel'
import Icon24Camera from '@vkontakte/icons/dist/24/camera'
import Icon24Video from '@vkontakte/icons/dist/24/video'
import Toggler from '../components/Toggler'
import './CreateQuestionModal.sass'

import Modal from '../components/Modal'

import { Input, Button, Textarea } from '@happysanta/vk-app-ui'

const mapStateToProps = state => ({
	...state,
})

const mapDispatchToProps = dispatch => ({
	addPoll: () => dispatch(addPoll()),
	switchFilter: filter => dispatch(changePollsFilter(filter)),
	openSettingsOfNewQuestion: () => dispatch(openSettingsOfNewQuestion()),
	closeSettingsOfNewQuestion: () => dispatch(closeSettingsOfNewQuestion()),
	addQuestionInput: () => dispatch(addQuestionInput()),
	updateFieldInOptionInput: (index, value) => dispatch(updateFieldInOptionInput(index, value)),
	deleteInputInQuestion: index => dispatch(deleteInputInQuestion(index)),
	switchTypeQuestion: () => dispatch(switchTypeQuestion()),
	updateNameNewQuestion: new_name => dispatch(updateNameNewQuestion(new_name)),
	addQuestion: (new_question, poll_id) => dispatch(addQuestion(new_question, poll_id)),
	switchQuestionWithButton: question_id => dispatch(switchQuestionWithButton(question_id)),
})

class CreateQuestionModal extends React.Component {
	render() {
		return (
			<Modal>
				<div className="BlueTitle">
					<div className="BlueTitle__block">
						<div className="BlueTitle__title">Создание вопроса</div>
						<Icon16Cancel fill="white" onClick={this.props.closeSettingsOfNewQuestion} />
					</div>
				</div>
				<div className="Modal__content">
					<div className="item">
						<div className="title">Вопрос</div>
						<Textarea
							placeholder="Введите вопрос..."
							value={this.props.cur_question.name}
							onChange={e => this.props.updateNameNewQuestion(e.target.value)}
							rows={2}
						/>
					</div>
					<div className="item type">
						<div className="title">Тип ответа</div>
						<div>
							<div
								className={classNames('radiobtn', {
									on: this.props.cur_question.type === true,
								})}
								onClick={() => {
									if (this.props.cur_question.type !== true) this.props.switchTypeQuestion()
								}}
							>
								С вариантом ответа
							</div>
							<div
								className={classNames('radiobtn', {
									on: this.props.cur_question.type === false,
								})}
								onClick={() => {
									if (this.props.cur_question.type !== false) this.props.switchTypeQuestion()
								}}
							>
								Со свободным ответом
							</div>
						</div>
					</div>
					{this.props.cur_question.type !== false && (
						<div className="item last">
							<div className="title">Варианты ответа</div>
							{this.props.cur_question.options.map((option, i) => (
								<div
									key={option.index || option._id}
									style={{
										position: 'relative',
										width: '100%',
									}}
								>
									{i === this.props.cur_question.options.length - 1 ? (
										<Input
											className="input add before_button"
											placeholder="Добавить вариант"
											onClick={this.props.addQuestionInput}
										/>
									) : (
										<Input
											className="input"
											value={option.value}
											onChange={e =>
												this.props.updateFieldInOptionInput(
													option.index || option._id,
													e.target.value
												)
											}
											placeholder={`Вариант ${i + 1}`}
										/>
									)}
									{i > 1 && (
										<div
											className="icon"
											style={{ position: 'absolute' }}
											onClick={() => this.props.deleteInputInQuestion(option.index || option._id)}
										>
											<Icon16Cancel fill="#BFC8D3" />
										</div>
									)}
								</div>
							))}
						</div>
					)}

					{this.props.cur_question.type !== false && (
						<div className="Settings__item Toggler_active">
							Кнопки с вариантами
							<Toggler
								mobile={true}
								className="switch"
								checked={this.props.cur_question.withButtons}
								onChange={() => this.props.switchQuestionWithButton(this.props.cur_question._id)}
							/>
						</div>
					)}
				</div>
				<div className="GreyFooter">
					<div className="item" onClick={this.props.closeSettingsOfNewQuestion}>
						<Button type="transparent">Отмена</Button>
					</div>
					<div
						className="item"
						onClick={() => this.props.addQuestion(this.props.cur_question, this.props.cur_poll._id)}
					>
						<Button type="default">Добавить вопрос</Button>
					</div>
				</div>
			</Modal>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreateQuestionModal)
