import React from 'react'
import { connect } from 'react-redux'
import {
	addPoll,
	backToSettings,
	openQuestionActionMenu,
	chooseAnswerAction,
	openSettingsOfNewQuestion,
	closeSettingsOfNewQuestion,
	deleteQuestion,
	openQuestionEditorModal,
	hideQuestion,
	replaceQuestionPosition,
} from '../actions/actions'

import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import { Panel, PanelHeader, HeaderButton } from '@vkontakte/vkui'

import { Button } from '@happysanta/vk-app-ui'

import './Contructor.sass'

import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back'

import AddQuestion from '../components/AddQuestion'
import Question from '../components/Question'

const mapStateToProps = state => ({
	...state,
})

const mapDispatchToProps = dispatch => ({
	addPoll: () => dispatch(addPoll()),
	openQuestionActionMenu: answer_id => dispatch(openQuestionActionMenu(answer_id)),
	chooseAnswerAction: (answer_id, action_question_id) =>
		dispatch(chooseAnswerAction(answer_id, action_question_id)),
	openSettingsOfNewQuestion: () => dispatch(openSettingsOfNewQuestion()),
	closeSettingsOfNewQuestion: () => dispatch(closeSettingsOfNewQuestion()),
	deleteQuestion: question_id => dispatch(deleteQuestion(question_id)),
	backToSettings: () => dispatch(backToSettings()),
	openQuestionEditorModal: edit_question => dispatch(openQuestionEditorModal(edit_question)),
	hideQuestion: (question_id, new_status) => dispatch(hideQuestion(question_id, new_status)),
	replaceQuestionPosition: (oldIndex, newIndex, poll_id) =>
		dispatch(replaceQuestionPosition(oldIndex, newIndex, poll_id)),
})

class Constructor extends React.Component {
	render() {
		const SortableQuestion = SortableElement(({ question }) => (
			<Question
				_id={question._id}
				title={`Вопрос ${question.counter}`}
				name={question.name}
				activeAnswer={this.props.activeAnswer}
				onOpenMenu={this.props.openQuestionActionMenu}
				onSelectMenu={this.props.chooseAnswerAction}
				delete={() => {
					this.props.deleteQuestion(question._id)
				}}
				onEdit={() => {
					this.props.openQuestionEditorModal(question)
				}}
				isHidden={question.hidden}
				onHide={() => this.props.hideQuestion(question._id, !question.hidden)}
				options={question.options}
				questions={this.props.pollQuestions}
				photos={question.photos}
			/>
		))

		const SortableQuestionsList = SortableContainer(() => {
			return (
				<div>
					{this.props.pollQuestions.map((question, i) => (
						<SortableQuestion question={question} key={question._id} index={i} />
					))}
				</div>
			)
		})
		return (
			<Panel id={this.props.id}>
				<PanelHeader
					left={
						<HeaderButton onClick={this.props.backToSettings}>
							<Icon24BrowserBack /> Назад
						</HeaderButton>
					}
					right={<AddQuestion onClick={this.props.openSettingsOfNewQuestion} />}
				>
					{this.props.cur_poll.name}
				</PanelHeader>
				{this.props.pollQuestions.length > 0 ? (
					<SortableQuestionsList
						axis="y"
						lockAxis="y"
						onSortEnd={({ oldIndex, newIndex }) => {
							this.props.replaceQuestionPosition(oldIndex, newIndex, this.props.cur_poll._id)
						}}
						useWindowAsScrollContainer={true}
						helperClass="SortingItem"
						shouldCancelStart={e =>
							!(
								(e.target.href !== undefined && e.target.href.animVal === '#more_28') ||
								e.target.innerHTML ===
									'<use xlink:href="#more_28" style="fill: currentcolor;"></use>'
							)
						}
					/>
				) : (
					<div className="NoPollsInList">
						<div className="item">
							<div className="text">Вопросов еще нет</div>
							<div>
								<Button onClick={this.props.openSettingsOfNewQuestion}>Создать вопрос</Button>
							</div>
						</div>
					</div>
				)}
			</Panel>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Constructor)
