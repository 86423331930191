import {
	ADD_POLL,
	SWITCH_FILTER,
	OPEN_SETTINGS_FOR_NEW_QUESTION,
	CLOSE_SETTINGS_FOR_NEW_QUESTION,
	OPEN_SETTINGS_FOR_NEW_POLL,
	CLOSE_SETTINGS_FOR_NEW_POLL,
	UPDATE_FIELD,
	UPDATE_CUR_POLL_FIELD,
	FETCH_POLLS,
	OPEN_POLL_SETTINGS,
	SAVE_POLL_CHANGES,
	DELETE_POLL,
	BACK_TO_LIST,
	BACK_TO_SETTINGS,
	SAVE_POLL_CHANGES_FAIL,
	OPEN_CONSTRUCTOR,
	OPEN_QUESTION_ACTION_MENU,
	CHOOSE_ANSWER_ACTION,
	ADD_QUESTION_INPUT,
	EDIT_QUESTION_OPTIONS_INPUT,
	UPDATE_FIELD_IN_OPTION_INPUT,
	DELETE_INPUT_IN_QUESTION,
	SWITCH_TYPE_QUESTION,
	UPDATE_NAME_NEW_QUESTION,
	ADD_QUESTION,
	DELETE_QUESTION,
	OPEN_QUESTION_EDITOR,
	EDIT_QUESTION,
	OPEN_START_POLL_MODAL,
	CLOSE_START_POLL_MODAL,
	OPEN_EDIT_QUESTION_MODAL,
	CLOSE_EDIT_QUESTION_MODAL,
	MARK_OPTION_AS_DELETED,
	START_POLL,
	HIDE_QUESTION,
	FLAUNT_QUESTION,
	RESET_RESULT_OF_POLL,
	REPLACE_QUESTION_POSITION,
	SWITCH_QUESTION_BUTTONS,
	ADD_ATTACH_OPEN,
	ADD_ATTACH_CLOSE,
	ADDED_ATTACH,
	DELETED_ATTACH,
} from './types'

import ajax from '../ajax'

export const changePollsFilter = filter => ({ type: SWITCH_FILTER, payload: filter })

export const openSettingsOfNewQuestion = () => ({
	type: OPEN_SETTINGS_FOR_NEW_QUESTION,
})
export const closeSettingsOfNewQuestion = () => ({
	type: CLOSE_SETTINGS_FOR_NEW_QUESTION,
})
export const openSettingsOfNewPoll = () => ({
	type: OPEN_SETTINGS_FOR_NEW_POLL,
})
export const closeSettingsOfNewPoll = () => ({
	type: CLOSE_SETTINGS_FOR_NEW_POLL,
})
export const backToList = () => ({
	type: BACK_TO_LIST,
})
export const backToSettings = () => ({
	type: BACK_TO_SETTINGS,
})

export const updateField = (name, value) => ({
	type: UPDATE_FIELD,
	payload: {
		name,
		value,
	},
})
export const updateCurPollField = (name, value) => ({
	type: UPDATE_CUR_POLL_FIELD,
	payload: {
		name,
		value,
	},
})
export const addPoll = name => async dispatch => {
	const {
		data: { response: newPoll },
	} = await ajax('/api/newPoll', {
		name,
	})
	dispatch({
		type: ADD_POLL,
		payload: newPoll,
		newPollName: '',
	})
	openPollSettings(newPoll._id)(dispatch)
}

export const fetchData = () => async dispatch => {
	const {
		data: {
			response: { activePolls, notActivePolls },
		},
	} = await ajax('/api/getPolls', {})

	dispatch({
		type: FETCH_POLLS,
		payload: { activePolls, notActivePolls },
	})
}

export const openPollSettings = poll_id => async dispatch => {
	const {
		data: { response },
	} = await ajax('/api/getPoll', { poll_id })
	dispatch({ type: OPEN_POLL_SETTINGS, payload: response })
}

export const savePollChanges = (poll_id, new_poll) => async dispatch => {
	const {
		data: { response, error },
	} = await ajax('/api/savePoll', { poll_id, new_poll })
	if (error !== undefined) {
		dispatch({
			type: SAVE_POLL_CHANGES_FAIL,
			payload: error.error_msg,
		})
		return
	}

	dispatch({ type: SAVE_POLL_CHANGES, payload: response })

	const {
		data: {
			response: { activePolls, notActivePolls },
		},
	} = await ajax('/api/getPolls', {})

	dispatch({
		type: FETCH_POLLS,
		payload: { activePolls, notActivePolls },
	})
}

export const deletePoll = poll_id => async dispatch => {
	if (window.confirm('Удалить опрос?') === true) {
		const {
			data: { response },
		} = await ajax('/api/deletePoll', { poll_id })
		dispatch({ type: DELETE_POLL, payload: poll_id })
	}
}

export const openConstructor = poll_id => async dispatch => {
	const {
		data: {
			response: { questions, counter },
		},
	} = await ajax('/api/getQuestionsInPoll', { poll_id })
	dispatch({
		type: OPEN_CONSTRUCTOR,
		payload: { questions, counter },
	})
}

export const openQuestionActionMenu = answer_id => ({
	type: OPEN_QUESTION_ACTION_MENU,
	payload: answer_id,
})

export const chooseAnswerAction = (answer_id, action_question_id) => async dispatch => {
	const {
		data: { response },
	} = await ajax('/api/setOptionNextQuestion', {
		option_id: answer_id,
		next_question: action_question_id,
	})
	dispatch({
		type: CHOOSE_ANSWER_ACTION,
		payload: { answer_id, action_question_id },
	})
}

export const addQuestionInput = () => ({
	type: ADD_QUESTION_INPUT,
})

export const editQuestionsOptionsInput = (index, value) => ({
	type: EDIT_QUESTION_OPTIONS_INPUT,
	payload: {
		index,
		value,
	},
})

export const updateFieldInOptionInput = (index, value) => ({
	type: UPDATE_FIELD_IN_OPTION_INPUT,
	payload: { index, value },
})

export const deleteInputInQuestion = index => ({
	type: DELETE_INPUT_IN_QUESTION,
	payload: index,
})
export const switchTypeQuestion = () => ({
	type: SWITCH_TYPE_QUESTION,
})

export const updateNameNewQuestion = new_name => ({
	type: UPDATE_NAME_NEW_QUESTION,
	payload: new_name,
})

export const addQuestion = (new_question, poll_id) => async dispatch => {
	const {
		data: { response },
	} = await ajax('/api/addQuestion', {
		new_question: {
			...new_question,
			options: new_question.options.map(option => ({ name: option.value })),
		},
		poll_id,
	})
	dispatch({
		type: ADD_QUESTION,
		payload: response,
	})
}

export const deleteQuestion = question_id => async dispatch => {
	const {
		data: { response },
	} = await ajax('/api/deleteQuestion', { question_id })

	dispatch({
		type: DELETE_QUESTION,
		payload: question_id,
	})
}

export const openQuestionEditor = () => ({ type: OPEN_QUESTION_EDITOR })

export const editQuestion = edited_question => async dispatch => {
	const {
		data: { response },
	} = await ajax('/api/editQuestion', { edited_question })

	dispatch({
		type: EDIT_QUESTION,
		payload: response,
	})
}

export const markOptionAsDeleted = option_id => ({
	type: MARK_OPTION_AS_DELETED,
	payload: option_id,
})

export const openQuestionEditorModal = edit_question => ({
	type: OPEN_EDIT_QUESTION_MODAL,
	payload: edit_question,
})

export const closeQuestionEditorModal = () => ({
	type: CLOSE_EDIT_QUESTION_MODAL,
})

export const closeStartPollModal = () => ({
	type: CLOSE_START_POLL_MODAL,
})

export const openStartPollModal = () => ({
	type: OPEN_START_POLL_MODAL,
})

export const startPoll = (poll_id, user_ids) => async dispatch => {
	const {
		data: { response, error },
	} = await ajax('/api/startPoll', {
		poll_id,
		user_ids,
	})
	if (error !== undefined) alert('Произошла ошибка при рассылке')
	else
		dispatch({
			type: START_POLL,
		})
}

export const hideQuestion = (question_id, new_status) => async dispatch => {
	const {
		data: { response },
	} = await ajax('/api/changeQuestionHiddenStatus', {
		question_id,
		new_status,
	})
	dispatch({
		type: new_status ? HIDE_QUESTION : FLAUNT_QUESTION,
		payload: { question_id },
	})
}

export const resetResultsOfPoll = poll_id => async dispatch => {
	if (window.confirm('Сбросить результаты опроса?') === true) {
		const {
			data: { response },
		} = await ajax('/api/resetResultsOfPoll', {
			poll_id,
		})
		dispatch({ type: RESET_RESULT_OF_POLL })
	}
}

export const replaceQuestionPosition = (oldIndex, newIndex, poll_id) => async dispatch => {
	dispatch({
		type: REPLACE_QUESTION_POSITION,
		payload: {
			oldIndex,
			newIndex,
			poll_id,
		},
	})
	const {
		data: { repsonse },
	} = await ajax('/api/replaceQuestion', { poll_id, oldIndex, newIndex })
}

export const switchQuestionWithButton = question_id => ({
	type: SWITCH_QUESTION_BUTTONS,
	question_id,
})

export const openAttachModal = (question_id, type) => ({
	type: ADD_ATTACH_OPEN,
	question_id,
	type_of_attach: type,
})

export const closeAttachModal = () => ({
	type: ADD_ATTACH_CLOSE,
})

export const addAttach = (question_id, url) => async dispatch => {
	const {
		data: { response, error },
	} = await ajax('/api/addAttachmentToQuestionByLink', {
		question_id,
		url,
	})
	if (error) alert('Неправильный формат ссылки.')
	else
		dispatch({
			type: ADDED_ATTACH,
			attachment: response,
		})
}

export const deleteAttach = attachment_id => ({ type: DELETED_ATTACH, attachment_id })
