import 'core-js/es6/map'
import 'core-js/es6/set'
import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import configureStore from './store'

import connect from '@vkontakte/vkui-connect'
import App from './App'
// import registerServiceWorker from './sw';

// Init VK App
connect.send('VKWebAppInit', {})

const initialState = {
	activePanel: 'PollsList',
	active_polls: [],
	active_polls_count: 0,
	no_active_polls: [],
	no_active_polls_count: 0,
	polls_filter: 'no_active',
	isCreateQuestion: false,
	isCreatePoll: false,
	isStartingPoll: false,
	isEditingQuestion: false,
	cur_poll: null,
	cur_poll_save_status: null,
	cur_poll_questions: null,
	cur_question: null,
	activeAnswer: 1,
	last_question_counter: null,
	start_poll_user_list: '',
	agreeStart: false,
	pollQuestions: [],
	add_attach_url: null,
	add_attach_question_id: null,
	add_attach_type: null,
}

// Если вы хотите, чтобы ваше веб-приложение работало в оффлайне и загружалось быстрее,
// расскомментируйте строку с registerServiceWorker();
// Но не забывайте, что на данный момент у технологии есть достаточно подводных камней
// Подробнее про сервис воркеры можно почитать тут — https://vk.cc/8MHpmT
// registerServiceWorker();

ReactDOM.render(
	<Provider store={configureStore(initialState)}>
		<App />
	</Provider>,
	document.getElementById('root')
)
