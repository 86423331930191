import React from 'react'
import classNames from 'classnames'

import './ActiveStatus.sass'

const ActiveStatus = props => (
	<div className={classNames('ActiveStatus__block')}>
		<div
			className={classNames('ActiveStatus', {
				active: props.polls_filter === 'active',
			})}
			onClick={() => {
				props.onChangeFilter('active')
			}}
		>
			Активные
		</div>
		<div
			className={classNames('ActiveStatus', {
				active: props.polls_filter !== 'active',
			})}
			onClick={() => {
				props.onChangeFilter('no_active')
			}}
		>
			Неактивные
		</div>
	</div>
)

export default React.memo(ActiveStatus)
