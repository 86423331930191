import axios from 'axios'
import queryString from 'query-string'

const parsed = queryString.parse(window.location.search)

export default async (path, data) => {
	return axios.post(path, {
		...parsed,
		...data,
	})
}
