import React from 'react'
import { connect } from 'react-redux'
import {
	changePollsFilter,
	addPoll,
	fetchData,
	closeSettingsOfNewPoll,
	updateField,
} from '../actions/actions'

import Icon16Cancel from '@vkontakte/icons/dist/16/cancel'

import './CreateQuestionModal.sass'
import classNames from 'classnames'
import Modal from '../components/Modal'

import { Input, Button, Textarea } from '@happysanta/vk-app-ui'

const mapStateToProps = state => ({
	...state,
})

const mapDispatchToProps = dispatch => ({
	addPoll: name => dispatch(addPoll(name)),
	fetchData: s => dispatch(fetchData(s)),
	switchFilter: filter => dispatch(changePollsFilter(filter)),
	closeSettingsOfNewPoll: () => dispatch(closeSettingsOfNewPoll()),
	updateField: ({ target }) => {
		const { name, value } = target

		dispatch(updateField(name, value))
	},
})

class CreatePollModal extends React.Component {
	render() {
		return (
			<Modal>
				<div className="BlueTitle">
					<div className="BlueTitle__block">
						<div className="BlueTitle__title">Создание опроса</div>
						<Icon16Cancel fill="white" onClick={this.props.closeSettingsOfNewPoll} />
					</div>
				</div>
				<div className="Modal__content">
					<div className="item last">
						<Textarea
							name="newPollName"
							value={this.props.newPollName}
							placeholder="Введите вопрос..."
							onChange={this.props.updateField}
							rows={2}
						/>
					</div>
				</div>
				<div className="GreyFooter">
					<div className="item">
						<Button type="transparent" onClick={this.props.closeSettingsOfNewPoll}>
							Отмена
						</Button>
					</div>
					<div className="item">
						<Button
							type="default"
							onClick={() => {
								if (this.props.newPollName !== undefined && this.props.newPollName.length > 0)
									this.props.addPoll(this.props.newPollName)
								else alert('Введите название опроса')
							}}
							className={classNames('btn', {
								disable: !(
									this.props.newPollName !== undefined && this.props.newPollName.length > 0
								),
							})}
						>
							Добавить опрос
						</Button>
					</div>
				</div>
			</Modal>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreatePollModal)
