import {
	SWITCH_FILTER,
	OPEN_SETTINGS_FOR_NEW_QUESTION,
	CLOSE_SETTINGS_FOR_NEW_QUESTION,
	OPEN_SETTINGS_FOR_NEW_POLL,
	CLOSE_SETTINGS_FOR_NEW_POLL,
	UPDATE_FIELD,
	UPDATE_CUR_POLL_FIELD,
	ADD_POLL,
	FETCH_POLLS,
	OPEN_POLL_SETTINGS,
	SAVE_POLL_CHANGES,
	DELETE_POLL,
	BACK_TO_LIST,
	BACK_TO_SETTINGS,
	SAVE_POLL_CHANGES_FAIL,
	OPEN_QUESTION_ACTION_MENU,
	CHOOSE_ANSWER_ACTION,
	OPEN_CONSTRUCTOR,
	ADD_QUESTION_INPUT,
	UPDATE_FIELD_IN_OPTION_INPUT,
	DELETE_INPUT_IN_QUESTION,
	SWITCH_TYPE_QUESTION,
	UPDATE_NAME_NEW_QUESTION,
	ADD_QUESTION,
	DELETE_QUESTION,
	OPEN_START_POLL_MODAL,
	CLOSE_START_POLL_MODAL,
	EDIT_QUESTION,
	OPEN_EDIT_QUESTION_MODAL,
	CLOSE_EDIT_QUESTION_MODAL,
	MARK_OPTION_AS_DELETED,
	START_POLL,
	HIDE_QUESTION,
	FLAUNT_QUESTION,
	RESET_RESULT_OF_POLL,
	REPLACE_QUESTION_POSITION,
	SWITCH_QUESTION_BUTTONS,
	ADD_ATTACH_OPEN,
	ADD_ATTACH_CLOSE,
	ADDED_ATTACH,
	DELETED_ATTACH,
} from '../actions/types'

import arrayMove from 'array-move'

export default (state, action) => {
	switch (action.type) {
		case SWITCH_FILTER:
			return {
				...state,
				polls_filter: action.payload,
			}
		case OPEN_SETTINGS_FOR_NEW_QUESTION:
			return {
				...state,
				isCreateQuestion: true,
				last_index_of_option_inputs: 2,
				withButtons: false,
				cur_question: {
					name: '',
					type: true,
					options: [
						{
							index: 1,
							value: '',
						},
						{
							index: 2,
							value: '',
						},
						{
							index: 3,
							value: '',
						},
					],
				},
			}
		case ADD_QUESTION_INPUT:
			return {
				...state,
				last_index_of_option_inputs: state.last_index_of_option_inputs + 1,
				cur_question: {
					...state.cur_question,
					options: [
						...state.cur_question.options,
						{
							index: state.last_index_of_option_inputs + 1,
							value: '',
						},
					],
				},
			}

		case UPDATE_FIELD_IN_OPTION_INPUT:
			return {
				...state,
				last_index_of_option_inputs: state.last_index_of_option_inputs + 1,
				cur_question: {
					...state.cur_question,
					options: state.cur_question.options.map(option =>
						(option.index || option._id) === (action.payload.index || action.payload._id)
							? {
									...option,
									value: action.payload.value,
									name: action.payload.value,
							  }
							: option
					),
				},
			}

		case DELETE_INPUT_IN_QUESTION:
			return {
				...state,
				cur_question: {
					...state.cur_question,
					options: state.cur_question.options.filter(
						option =>
							(option.index || option._id) !==
							(action.payload.index || action.payload._id || action.payload)
					),
				},
			}
		case SWITCH_TYPE_QUESTION:
			return {
				...state,
				cur_question: {
					...state.cur_question,
					type: !state.cur_question.type,
				},
			}
		case CLOSE_SETTINGS_FOR_NEW_QUESTION:
			return {
				...state,
				isCreateQuestion: false,
				cur_question: null,
			}
		case OPEN_SETTINGS_FOR_NEW_POLL:
			return {
				...state,
				isCreatePoll: true,
			}
		case CLOSE_SETTINGS_FOR_NEW_POLL:
			return {
				...state,
				isCreatePoll: false,
			}
		case UPDATE_FIELD:
			return {
				...state,
				[action.payload.name]: action.payload.value,
			}
		case UPDATE_CUR_POLL_FIELD:
			return {
				...state,
				cur_poll: {
					...state.cur_poll,
					[action.payload.name]: action.payload.value,
				},
			}
		case ADD_POLL:
			return {
				...state,
				isCreatePoll: false,
				newPollName: '',
				no_active_polls: [...state.no_active_polls, action.payload],
			}
		case FETCH_POLLS:
			return {
				...state,
				active_polls: [...action.payload.activePolls],
				no_active_polls: [...action.payload.notActivePolls],
			}
		case OPEN_POLL_SETTINGS:
			return {
				...state,
				cur_poll: action.payload,
				activePanel: 'Settings',
			}
		case SAVE_POLL_CHANGES:
			return {
				...state,
				active_polls: state.active_polls.map(e =>
					e._id === action.payload._id ? action.payload : e
				),
				no_active_polls: state.no_active_polls.map(e =>
					e._id === action.payload._id ? action.payload : e
				),
				cur_poll_save_status: true,
			}
		case SAVE_POLL_CHANGES_FAIL:
			return {
				...state,
				cur_poll_save_status: false,
			}
		case DELETE_POLL:
			return {
				...state,
				cur_poll: null,
				activePanel: 'PollsList',
				cur_poll_save_status: null,
				active_polls: state.active_polls.filter(e => e._id !== action.payload),
				no_active_polls: state.no_active_polls.filter(e => e._id !== action.payload),
			}
		case BACK_TO_LIST:
			return {
				...state,
				activePanel: 'PollsList',
				cur_poll: null,
				cur_poll_save_status: null,
			}
		case BACK_TO_SETTINGS:
			return {
				...state,
				activePanel: 'Settings',
			}
		case OPEN_QUESTION_ACTION_MENU:
			return {
				...state,
				activeAnswer: action.payload,
			}
		case CHOOSE_ANSWER_ACTION:
			return {
				...state,
				activeAnswer: null,
				pollQuestions: state.pollQuestions.map(question => ({
					...question,
					options: question.options.map(option =>
						option._id === action.payload.answer_id
							? { ...option, next_question: action.payload.action_question_id }
							: option
					),
				})),
			}
		case OPEN_CONSTRUCTOR:
			return {
				...state,
				activePanel: 'Constructor',
				pollQuestions: action.payload.questions,
				last_question_counter: action.payload.counter,
			}
		case UPDATE_NAME_NEW_QUESTION:
			return {
				...state,
				cur_question: {
					...state.cur_question,
					name: action.payload,
				},
			}
		case ADD_QUESTION:
			return {
				...state,
				pollQuestions: [...state.pollQuestions, action.payload],
				isCreateQuestion: false,
			}
		case DELETE_QUESTION:
			return {
				...state,
				pollQuestions: state.pollQuestions
					.filter(e => e._id !== action.payload)
					.map(question => ({
						...question,
						options: question.options.map(option =>
							option.next_question === action.payload
								? { ...option, next_question: undefined }
								: option
						),
					})),
			}
		case OPEN_START_POLL_MODAL:
			return {
				...state,
				isStartingPoll: true,
			}
		case CLOSE_START_POLL_MODAL:
			return {
				...state,
				isStartingPoll: false,
				start_poll_user_list: '',
			}
		case EDIT_QUESTION:
			return {
				...state,
				isEditingQuestion: false,
				pollQuestions: state.pollQuestions.map(e =>
					e._id === action.payload._id ? action.payload : e
				),
			}
		case OPEN_EDIT_QUESTION_MODAL:
			return {
				...state,
				isEditingQuestion: true,
				last_index_of_option_inputs: 1,
				cur_question: {
					...action.payload,
					options: [
						...action.payload.options,
						{
							index: 1,
							name: '',
						},
					],
				},
			}
		case CLOSE_EDIT_QUESTION_MODAL:
			return {
				...state,
				isEditingQuestion: false,
				cur_question: null,
			}
		case MARK_OPTION_AS_DELETED:
			return {
				...state,
				cur_question: {
					...state.cur_question,
					options: state.cur_question.options.map(e =>
						e._id === action.payload
							? {
									...e,
									deleted: true,
							  }
							: e
					),
				},
			}
		case START_POLL:
			return {
				...state,
				isStartingPoll: false,
			}
		case HIDE_QUESTION:
			return {
				...state,
				pollQuestions: state.pollQuestions.map(e =>
					e._id === action.payload.question_id ? { ...e, hidden: true } : e
				),
			}

		case FLAUNT_QUESTION:
			return {
				...state,
				pollQuestions: state.pollQuestions.map(e =>
					e._id === action.payload.question_id ? { ...e, hidden: false } : e
				),
			}
		case RESET_RESULT_OF_POLL:
			return {
				...state,
				cur_poll_save_status: true,
			}
		case REPLACE_QUESTION_POSITION:
			return {
				...state,
				pollQuestions: arrayMove(
					state.pollQuestions,
					action.payload.oldIndex,
					action.payload.newIndex
				).map((e, i) => ({
					...e,
					counter: i + 1,
				})),
			}
		case SWITCH_QUESTION_BUTTONS:
			return {
				...state,
				pollQuestions: state.pollQuestions.map(e =>
					e._id === state.cur_question._id
						? { ...e, withButtons: !state.cur_question.withButtons }
						: e
				),
				cur_question: { ...state.cur_question, withButtons: !state.cur_question.withButtons },
			}
		case ADD_ATTACH_OPEN:
			return {
				...state,
				add_attach_question_id: action.question_id,
				add_attach_type: action.type_of_attach,
			}
		case ADD_ATTACH_CLOSE:
			return {
				...state,
				add_attach_question_id: null,
				add_attach_type: null,
			}
		case ADDED_ATTACH:
			return {
				...state,
				cur_question: {
					...state.cur_question,
					photos: [...state.cur_question.photos, action.attachment],
				},
				add_attach_question_id: null,
				add_attach_type: null,
				add_attach_url: null,
			}
		case DELETED_ATTACH:
			return {
				...state,
				cur_question: {
					...state.cur_question,
					photos: state.cur_question.photos.filter(e => e._id !== action.attachment_id),
				},
			}
		default:
			return state
	}
}
