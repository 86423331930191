import React from 'react'

import classnames from 'classnames'

import './Toggler.sass'

const Toggler = props => (
	<div
		className={classnames(
			'Toggler',
			{
				on: props.checked,
			},
			props.className
		)}
		onClick={props.onChange}
	/>
)

export default React.memo(Toggler)
