import React from 'react'

import './Question.sass'

import Photo from './Photo'

import Icon28More from '@vkontakte/icons/dist/28/more'
import Icon16Dropdown from '@vkontakte/icons/dist/16/dropdown'

const Question = props => (
	<div className="Question">
		<div className="header">
			<div className="title">
				<div className="more_icon">
					<Icon28More className="more_icon" />
				</div>
				<div className="text">{props.title}</div>
			</div>
			<div className="actions">
				<div className="item" onClick={props.onHide}>
					{props.isHidden ? 'Открыть' : 'Скрыть'}
				</div>
				·
				<div className="item" onClick={props.onEdit}>
					Редактировать
				</div>
				·
				<div className="item" onClick={props.delete}>
					Удалить
				</div>
			</div>
		</div>
		<div className="main">
			<div className="name">{props.name}</div>
			{props.options.map(option => (
				<div className="item">
					<div className="text">{option.name}</div>
					<div>
						<div className="action" onClick={() => props.onOpenMenu(option._id)}>
							{option.next_question == undefined
								? 'Добавить переход'
								: option.next_question === 'exit'
								? 'Завершить опрос'
								: `${props.questions.find(e => e._id === option.next_question).counter}. 
								${props.questions.find(e => e._id === option.next_question).name.substr(0, 20)}`}
							<Icon16Dropdown fill="#2A5885" />
						</div>
						{props.activeAnswer === option._id && (
							<div className="tooltip">
								<div className="actions" onMouseLeave={() => props.onOpenMenu(null)}>
									{props.questions
										.filter(e => e._id !== props._id)
										.map(Question => (
											<div
												className="action"
												onClick={() => {
													props.onSelectMenu(option._id, Question._id)
												}}
											>
												{`${Question.counter}. ${Question.name}`}
											</div>
										))}
									<div
										className="action"
										onClick={() => {
											props.onSelectMenu(option._id, 'exit')
										}}
									>
										Завершить опрос
									</div>
									<div
										className="action"
										onClick={() => {
											props.onSelectMenu(option._id, undefined)
										}}
									>
										Не выбрано
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			))}
			{props.photos.length > 0 && (
				<div className="Photos">
					{props.photos.map(photo => (
						<Photo url={photo.sizes[photo.sizes.length - 1].url} />
					))}
				</div>
			)}
		</div>
	</div>
)

export default React.memo(Question)
